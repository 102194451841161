import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Select from 'react-select/async';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import useDarkMode from '../../hooks/useDarkMode';
import Avatar, { AvatarStorage } from '../Avatar';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/authContext';
import Placeholder from '../../assets/img/avatar.png';
import PlaceholderWebp from '../../assets/img/avatar.webp';

const inputStyles = {
	color: 'inherit',
	background: '0px center',
	opacity: 1,
	width: '100%',
	gridArea: '1 / 1 / auto / auto',
	font: 'inherit',
	minWidth: '2px',
	border: '0px',
	margin: '0px',
	outline: '0px',
	padding: '0px',
};

export const Input = observer((props) => (
		<components.Input
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		autoCapitalize='none'
		autoComplete='off'
		autoCorrect='off'
		id='react-select-3-input'
		spellCheck='false'
		tabIndex='0'
		type='text'
		aria-autocomplete='list'
		aria-expanded='false'
		aria-haspopup='true'
		// eslint-disable-next-line jsx-a11y/role-has-required-aria-props
		role='combobox'
		aria-describedby='react-select-3-placeholder'
		style={inputStyles}
	/>
));

const Option = observer(({ data, children, ...props }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<components.Option key={data.hash} {...props}>
		<div className='d-flex align-items-center p-2'>
			<div className='flex-grow-0 me-2'>
				{data.images ? (
					<AvatarStorage images={data.images} size={46} />
				) : (
					<Avatar src={Placeholder} srcSet={PlaceholderWebp} size={46} />
				)}
			</div>
			<div className='flex-grow-1'>
				<div className='fw-bold'>{children}</div>
				{data.post ? (
					<div className='fw-light'>
						<small>{data.post}</small>
					</div>
				) : null}
			</div>
		</div>
	</components.Option>
));

Option.propTypes = {
	data: PropTypes.shape({
		images: PropTypes.arrayOf(
			PropTypes.shape({
				created_at: PropTypes.string,
				path: PropTypes.string,
				storage: PropTypes.string,
				type: PropTypes.string,
				updated_at: PropTypes.string,
			}),
		),
		post: PropTypes.string,
	}).isRequired,
	children: PropTypes.string.isRequired,
};

export const useReactSelectStyles = (props) => {
	const { darkModeStatus } = useDarkMode();
	return {
		container: (provided) => ({
			...provided,
			width: '100%',
			flex: '1 1',
			...(props?.container ? props.container : {}),
		}),
		control: (provided, state) => ({
			...provided,
			color: darkModeStatus ? '#e7eef8' : '#323232',
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isDisabled ? '#e9ecef' : darkModeStatus ? '#212529' : '#f8f9fa',
			boxShadow: 'inset 0 1px 2px rgb(0 0 0 / 8%)',
			fontSize: '1rem',
			fontWeight: 600,
			lineHeight: 1.5,
			border: `1px solid ${darkModeStatus ? '#343a40' : '#f8f9fa'}`,
			borderRadius: '1rem',
			outline: state.isFocused ? '1px solid #b6aee9' : '',
			minHeight: '34.5px',
			opacity: state.isDisabled ? 1 : 'unset',
			...(props?.control ? props.control : {}),
		}),
		placeholder: (provided) => ({
			...provided,
			color: darkModeStatus ? '#e7eef8' : '#6c757d',
			flex: '1 1 auto',
			display: 'inline-grid',
			gridArea: '1 / 1 / 2 / 3',
			gridTemplateColumns: '1fr',
			whiteSpace: 'nowrap',
			...(props?.placeholder ? props.placeholder : {}),
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			backgroundColor: 'transparent',
			...(props?.indicatorSeparator ? props.indicatorSeparator : {}),
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: darkModeStatus ? '#e7eef8' : '#323232',
			padding: '6px',
			...(props?.dropdownIndicator ? props.dropdownIndicator : {}),
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: darkModeStatus ? '#212529' : '#f8f9fa',
			margin: '1px',
			...(props?.menu ? props.menu : {}),
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isFocused ? '#fff' : '',
			backgroundColor: state.isFocused ? '#0d6efd' : '',
			...(props?.option ? props.option : {}),
		}),
		input: (provided) => ({
			...provided,
			...inputStyles,
			gridTemplateColumns: '1fr',
			display: 'inline-grid',
			flex: '1 1 auto',
			...(props?.input ? props.input : {}),
		}),
	};
};

const ReactSelect = observer(
	({
		name,
		defaultValue,
		value,
		onSetValue,
		className,
		invalidFeedback,
		isTouched,
		isValid,
		setTouched,
		...props
	}) => {
		const { sendData } = useFetch();
		const styles = useReactSelectStyles();
		const auth = useAuth();

		const [message, setMessage] = useState('Нет пользователей');

		const load = useCallback(
			(inputValue, callback) => {
				const formData = new FormData();

				formData.set('token', auth.token);
				formData.set('q', inputValue);

				if (inputValue.length > 2) {
					sendData('/users/search/', formData)
						.then((data) => {
							if (Array.isArray(data)) {
								callback(
									data.map((user) => ({
										...user,
										label: `${user.surname} ${user.name}`,
										value: user.hash,
									})),
								);
							} else {
								if (data?.warning) {
									setMessage(data.warning);
								}
								callback([]);
							}
						})
						.catch(() => {
							callback([]);
						});
				} else {
					callback([]);
				}
			},
			[sendData, auth.token],
		);

		return (
			<>
				<Select
					name={name}
					defaultValue={defaultValue}
					value={value}
					loadOptions={load}
					components={{ Option, Input }}
					cacheOptions
					isSearchable
					isClearable
					blurInputOnSelect
					className={classNames(className, {
						'is-valid': isTouched[name] && isValid,
						'is-invalid': isTouched[name] && !isValid,
					})}
					placeholder='Начните вводить имя...'
					styles={styles}
					loadingMessage={() => 'Загрузка'}
					noOptionsMessage={() => message}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					onChange={(event, meta) => {
						
						if (meta.action === 'select-option') {
							console.log(event)
							onSetValue(event);
						} else if (meta.action === 'clear') {
							onSetValue('');
						}
						if (typeof setTouched === 'function') {
							setTouched({
								...isTouched,
								[name]: true,
							});
						}
					}}
				/>
				{isTouched[name] && invalidFeedback ? (
					<div className='invalid-feedback'>{invalidFeedback}</div>
				) : null}
			</>
		);
	},
);

ReactSelect.displayName = 'ReactSelect';

ReactSelect.propTypes = {
	name: PropTypes.string.isRequired,
	defaultValue: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	value: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	onSetValue: PropTypes.func.isRequired,
	className: PropTypes.string,
	invalidFeedback: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	isTouched: PropTypes.bool,
	isValid: PropTypes.bool,
	setTouched: PropTypes.func,
};

ReactSelect.defaultProps = {
	defaultValue: null,
	value: null,
	className: '',
	invalidFeedback: null,
	isTouched: false,
	isValid: null,
	setTouched: null,
};

export default ReactSelect;
